import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Outbound } from 'features/SupplyOrder/@types'

export const SLICE_NAME = 'outbound'

export const ASTRO_DRIVER = {
  id: 0,
  name: 'Astro',
}

export const initialState: Outbound.InitialStateType = {
  selectedSO: [],
  readyToShip: {
    informationTruckModalState: 'CLOSE',
    isLoading: false,
    form: {
      driver: null,
      driverName: '',
      plateNumber: '',
      vehicleType: null,
      freshInitialTemperature: '',
      freshFinalTemperature: '',
      selectedAstroDriver: null,
    },
    configurations: {
      supportedVehicleType: [],
      needToSetTemperatureLocationIds: [],
    },
    driverOptions: [],
    driversAstroOptions: [],
  },
  constants: {
    checker: [],
    packer: [],
  },
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setReadyToShipInformationTruckModalState: (
      state,
      { payload }: PayloadAction<Outbound.ModalStateType>,
    ) => {
      state.readyToShip.informationTruckModalState = payload
    },
    setSelectedSO: (state, { payload }: PayloadAction<Outbound.SupplyOrderType[]>) => {
      state.selectedSO = [...payload]
    },
    setReadyToShipIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.readyToShip.isLoading = payload
    },
    setReadyToShipForm: (
      state,
      { payload }: PayloadAction<{ field?: keyof Outbound.ReadyToShipFormType; value: never }>,
    ) => {
      const { field, value } = payload
      if (field) {
        state.readyToShip.form[field] = value
      } else {
        state.readyToShip.form = {
          ...state.readyToShip.form,
          ...(value as Partial<Outbound.ReadyToShipFormType>),
        }
      }
    },
    setConfigurations: (
      state,
      { payload }: PayloadAction<Outbound.InitialStateType['readyToShip']['configurations']>,
    ) => {
      state.readyToShip.configurations = payload
    },
    setDriverOptions: (state, { payload }: PayloadAction<Outbound.DriverType[]>) => {
      state.readyToShip.driverOptions = payload
    },
    setDriversAstroOptions: (state, { payload }: PayloadAction<Outbound.DriverAstroType[]>) => {
      state.readyToShip.driversAstroOptions = payload
    },
    setCheckerOptions: (
      state,
      { payload }: PayloadAction<Outbound.InitialStateType['constants']['checker']>,
    ) => {
      state.constants.checker = payload
    },
    setPackerOptions: (
      state,
      { payload }: PayloadAction<Outbound.InitialStateType['constants']['packer']>,
    ) => {
      state.constants.packer = payload
    },
    resetReadyToShipForm: (state) => {
      state.readyToShip.form = initialState.readyToShip.form
    },
  },
})

export const {
  resetState,
  setReadyToShipInformationTruckModalState,
  setSelectedSO,
  setReadyToShipIsLoading,
  setReadyToShipForm,
  setConfigurations,
  setDriverOptions,
  resetReadyToShipForm,
  setDriversAstroOptions,
  setPackerOptions,
  setCheckerOptions,
} = slice.actions
export default slice.reducer
