import createReducer from 'storeContext/reducers/createReducer'
import {
  PRODUCT_ADD_EDIT_RESET_STATE,
  PRODUCT_ADD_EDIT_SET_PRODUCT_DESCRIPTION_TEMP,
  PRODUCT_ADD_EDIT_SET_LOADING,
  PRODUCT_ADD_EDIT_SET_INPUT,
  PRODUCT_ADD_EDIT_ADD_CATEGORY,
  PRODUCT_ADD_EDIT_SET_CATEGORY,
  PRODUCT_ADD_EDIT_SET_CATEGORY_ACTIVE,
  PRODUCT_ADD_EDIT_ADD_IMAGE,
  PRODUCT_ADD_EDIT_SET_FORM_ERROR,
  PRODUCT_ADD_EDIT_MAP_STATE_EDIT,
  PRODUCT_ADD_EDIT_DELETE_CATEGORY,
  PRODUCT_ADD_EDIT_SET_POPUP_CONFIRMATION_CONSIGNMENT,
  PRODUCT_ADD_EDIT_SET_BUSINESS_OR_COMPANY_LIST,
  PRODUCT_ADD_EDIT_SET_PRIMARY_CATEGORY,
  PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_WARNING,
  PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_VALIDATION_WARNING,
  PRODUCT_ADD_EDIT_SET_PPN_LIST,
  PRODUCT_ADD_EDIT_SET_SELECTED_PPN,
  PRODUCT_ADD_EDIT_SET_PRODUCT_LABEL_IMAGE,
  PRODUCT_ADD_EDIT_SET_PRODUCT_LABEL_PROMOTION,
  PRODUCT_ADD_EDIT_ADD_PRODUCT_LABEL,
  PRODUCT_ADD_EDIT_DELETE_PRODUCT_LABEL,
} from 'storeContext/actionsType'

export const IMAGE_TYPES = ['small', 'medium', 'large']

const initialCategories = () => ({
  key: crypto.randomUUID(),
  category: null,
  isActive: true,
})

export const initialProductlabel = () => ({
  key: crypto.randomUUID(),
  label: null,
  type: 'ADD',
  isLoading: false,
})

export const initialStateProductAddEdit = {
  isLoading: false,
  product_name: '',
  product_sku_number: '',
  slug: '',
  max_qty: '',
  max_qty_duration: '',
  bom_category: null,
  base_uom: null,
  brand: null,
  product_type: null,
  product_description: '',
  critical_status: false,
  is_consignment: false,
  is_fragile: false,
  is_rotten: false,
  is_melting: false,
  is_eggs: false,
  is_refill: false,
  is_single_packaging: false,
  source_status: null,
  storage_handling: null,
  expiration: '',
  flush_out: '',
  product_age: '',
  expiration_reminder: '',
  product_weight: '',
  product_length: '',
  product_width: '',
  product_height: '',
  product_images: [],
  originalProductImage: [],
  businessUnit: {
    value: '',
    name: '',
  },
  company: {
    value: '',
    name: '',
  },
  relabeled_in_hub: false,
  categories: [{ ...initialCategories() }],
  productLabelImage: [{ ...initialProductlabel() }],
  productLabelPromotion: [{ ...initialProductlabel() }],
  ppnList: [],
  selectedPpnBuy: {
    id: 0,
    name: '',
  },
  selectedPpnSell: {
    id: 0,
    name: '',
  },
  selectedBusinessTagging: [],
  businessUnitList: [],
  businessTaggingList: [],
  companyList: [],
  errorForm: {},
  isShowPopupConfirmationConsignment: false,
  showPopupShelfLifeWarning: false,
  isUsingStandardCategory: false,
  isXdock: false,
  allowMixSku: false,
  isQr: false,
  primaryCategory: null,
  minimumShelfLifeOnReceving: '',
  minimumShelfLifeOnDelivery: '',
  minimumShelfLifeToCustomer: '',
  showPopupShelfLifeValidationWarning: false,
  productDescriptionTemp: '',
  disableDropdownBom: false,
}

const productAddEditReducer = createReducer({
  [PRODUCT_ADD_EDIT_RESET_STATE]: () => {
    return initialStateProductAddEdit
  },
  [PRODUCT_ADD_EDIT_SET_LOADING]: (state, { payload }) => {
    state.isLoading = payload
  },
  [PRODUCT_ADD_EDIT_SET_INPUT]: (state, { payload }) => {
    const { key, value } = payload
    state[key] = value
  },
  [PRODUCT_ADD_EDIT_ADD_CATEGORY]: (state) => {
    state.categories.push({ ...initialCategories(), isActive: false })
  },
  [PRODUCT_ADD_EDIT_SET_CATEGORY]: (state, { payload }, { current }) => {
    const { index, value } = payload
    state.categories[index].category = value
  },
  [PRODUCT_ADD_EDIT_SET_CATEGORY_ACTIVE]: (state, { payload }) => {
    const { index, value } = payload
    state.categories = state.categories.map((each) => ({
      ...each,
      isActive: false,
    }))
    state.categories[index].isActive = value
  },
  [PRODUCT_ADD_EDIT_DELETE_CATEGORY]: (state, { payload }) => {
    state.categories.splice(payload, 1)
  },
  [PRODUCT_ADD_EDIT_ADD_IMAGE]: (state, { payload }) => {
    const { value } = payload
    state.product_images = state.product_images.concat(value)
  },
  [PRODUCT_ADD_EDIT_SET_FORM_ERROR]: (state, { payload }) => {
    state.errorForm = payload
  },
  [PRODUCT_ADD_EDIT_SET_POPUP_CONFIRMATION_CONSIGNMENT]: (state, { payload }) => {
    state.isShowPopupConfirmationConsignment = payload
  },
  [PRODUCT_ADD_EDIT_SET_PRIMARY_CATEGORY]: (state, { payload }) => {
    state.primaryCategory = payload
  },

  [PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_WARNING]: (state, { payload }) => {
    state.showPopupShelfLifeWarning = payload
  },
  [PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_VALIDATION_WARNING]: (state, { payload }) => {
    state.showPopupShelfLifeValidationWarning = payload
  },
  [PRODUCT_ADD_EDIT_SET_PRODUCT_DESCRIPTION_TEMP]: (state, { payload }) => {
    state.productDescriptionTemp = payload
  },
  [PRODUCT_ADD_EDIT_MAP_STATE_EDIT]: (state, { payload }) => {
    const {
      product_attributes,
      product_images,
      product_categories,
      use_standard_ned,
      min_shelf_life_on_receiving,
      min_shelf_life_on_delivery,
      min_shelf_life_to_customer,
      bom_category,
      ...restProduct
    } = payload

    if (
      bom_category &&
      (bom_category.code === 'VIRTUAL' || bom_category.code === 'ASTRO_KITCHEN')
    ) {
      state.disableDropdownBom = true
    }
    state.isXdock = product_attributes.is_xdock
    state.allowMixSku = product_attributes.allow_mix_sku
    state.isQr = product_attributes.is_qr
    state.businessUnit = {
      name: product_attributes.business_unit_value,
      value: product_attributes.business_unit,
    }

    state.selectedBusinessTagging =
      product_attributes?.business_taggings?.map((item) => ({ ...item })) || []

    state.categories = product_categories.flatMap((each) => {
      const { category_childs, ...parent } = each
      const { primary_category } = parent
      if (category_childs.length) {
        return category_childs.map((eachChild) => ({
          key: crypto.randomUUID(),
          category: {
            ...eachChild,
            parent,
          },
          isActive: eachChild.primary_category,
        }))
      }
      return [
        {
          key: crypto.randomUUID(),
          category: each,
          isActive: category_childs.length ? false : primary_category,
        },
      ]
    })

    state.originalProductImage = [...product_images]
    state.isUsingStandardCategory = use_standard_ned
    state.minimumShelfLifeOnReceving = min_shelf_life_on_receiving || ''
    state.minimumShelfLifeOnDelivery = min_shelf_life_on_delivery || ''
    state.minimumShelfLifeToCustomer = min_shelf_life_to_customer || ''

    const restOfPayload = {
      ...restProduct,
      ...product_attributes,
    }

    state.selectedPpnBuy = {
      id: restProduct.ppn_id_buy,
      name: restProduct.ppn_buy.toString(),
    }
    state.selectedPpnSell = {
      id: restProduct.ppn_id_sell,
      name: restProduct.ppn_sell.toString(),
    }

    state.bom_category = bom_category

    Object.keys(restOfPayload).forEach((keys) => {
      if (keys === 'bom_category' && restOfPayload[keys]?.name === null) {
        state[keys] = { code: 'NONE', name: 'None' }
        return
      }
      state[keys] = restOfPayload[keys]
    })
    state.company = { name: product_attributes.company_value, value: product_attributes.company }
  },
  [PRODUCT_ADD_EDIT_SET_BUSINESS_OR_COMPANY_LIST]: (state, action) => {
    const { type, listData } = action.payload

    const mappingList = [
      { type: 'BUSINESS_UNIT', name: 'businessUnitList' },
      { type: 'BUSINESS_TAGGING', name: 'businessTaggingList' },
      { type: 'COMPANY', name: 'companyList' },
    ]

    const resultData = mappingList.find((item) => item.type === type)

    if (resultData) {
      const fieldName = resultData.name
      state[fieldName] = listData
    }
  },
  [PRODUCT_ADD_EDIT_SET_PPN_LIST]: (state, action) => {
    state.ppnList = action.payload
  },
  [PRODUCT_ADD_EDIT_SET_SELECTED_PPN]: (state, action) => {
    const { ppnType, selectedPpn } = action.payload
    if (ppnType === 'ppn_id_buy') {
      state.selectedPpnBuy = selectedPpn
      return
    }
    state.selectedPpnSell = selectedPpn
  },
  [PRODUCT_ADD_EDIT_SET_PRODUCT_LABEL_IMAGE]: (state, { payload }) => {
    const { index, value } = payload
    state.productLabelImage[index] = {
      ...state.productLabelImage[index],
      ...value,
    }
  },
  [PRODUCT_ADD_EDIT_SET_PRODUCT_LABEL_PROMOTION]: (state, { payload }) => {
    const { index, value } = payload
    state.productLabelPromotion[index] = {
      ...state.productLabelPromotion[index],
      ...value,
    }
  },
  [PRODUCT_ADD_EDIT_ADD_PRODUCT_LABEL]: (state, { payload: { field } }) => {
    state[field].push(initialProductlabel())
  },
  [PRODUCT_ADD_EDIT_DELETE_PRODUCT_LABEL]: (state, { payload }) => {
    const { key, field } = payload
    state[field] = state[field].filter((label) => label.key !== key)
  },
})

export default productAddEditReducer
