import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getValidateInformationTruck,
  getConfiguration,
  get3PL,
  putOnDelivery,
  putSupplyOrderGrandStatus,
  getDriversAstro,
  getListStaffByRoleAPI,
  postAssignCheckerPackerAPI,
} from 'features/SupplyOrder/services/outbound'
import { callErrorMsg } from 'helpers/errorMsg'
import { Outbound } from 'features/SupplyOrder/@types'
import { toastSuccess } from 'utils/toast'
import {
  SLICE_NAME,
  setConfigurations,
  setReadyToShipForm,
  setReadyToShipIsLoading,
  setDriverOptions,
  setDriversAstroOptions,
  ASTRO_DRIVER,
  setCheckerOptions,
  setPackerOptions,
} from './slice'

export const validateTruckInformation = createAsyncThunk(
  `${SLICE_NAME}/validateTruckInformation`,
  async (args: { loadingIds: number[]; locationId: number }, { dispatch, getState }) => {
    dispatch(setReadyToShipIsLoading(true))

    await dispatch(fetchDriversAstro(args.locationId)).unwrap()

    const {
      outbound: {
        readyToShip: { driverOptions, driversAstroOptions, configurations },
      },
    } = getState() as StoreStateType

    try {
      const {
        data: { data },
      } = await getValidateInformationTruck(args.loadingIds)

      const payload: Partial<Outbound.ReadyToShipFormType> = {
        driverName: data.driver_name,
        plateNumber: data.plate_number,
        freshInitialTemperature: `${data.start_fresh_temperature}`,
        freshFinalTemperature: `${data.end_fresh_temperature}`,
      }

      if (data.driver_type === 'ASTRO') {
        const selectedDriverAstro = driversAstroOptions.find(
          (driver) => driver.id === data.driver_id,
        )
        if (selectedDriverAstro) {
          payload.driver = ASTRO_DRIVER
          payload.selectedAstroDriver = selectedDriverAstro
          payload.vehicleType = {
            key: selectedDriverAstro.vehicle.code,
            value: selectedDriverAstro.vehicle.name,
          }
        }
      } else {
        const selectedDriver = driverOptions.find((driver) => driver.id === data.driver_id)
        payload.driver = selectedDriver || null
        payload.vehicleType =
          configurations.supportedVehicleType.find(
            (vehicle) => vehicle.key === data.vehicle_type,
          ) || null
      }

      dispatch(setReadyToShipForm({ value: payload as never }))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setReadyToShipIsLoading(false))
    }
  },
)

export const getConfigurationSO = createAsyncThunk(
  `${SLICE_NAME}/getConfigurationSO`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getConfiguration()
      dispatch(setConfigurations(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const get3PLProviders = createAsyncThunk(
  `${SLICE_NAME}/get3PLProviders`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await get3PL()

      dispatch(setDriverOptions(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchDriversAstro = createAsyncThunk(
  `${SLICE_NAME}/fetchDriversAstro`,
  async (locationId: number, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getDriversAstro(locationId)

      dispatch(setDriversAstroOptions(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const updateOnDelivery = createAsyncThunk(
  `${SLICE_NAME}/updateOnDelivery`,
  async (_, { getState }) => {
    const {
      outbound: { readyToShip, selectedSO },
    } = getState() as StoreStateType
    const { driver, vehicleType, selectedAstroDriver, ...form } = readyToShip.form

    const payload = {
      ...form,
      driverName: driver?.id ? form.driverName : selectedAstroDriver?.name || '',
      driverId: driver?.id || selectedAstroDriver?.id || null,
      driverProviderId: driver?.id || null,
      vehicleType: vehicleType?.key || '',
      loadingID: null,
      loadingIDs: selectedSO
        .map(({ supplyOrderLoadingId }) => supplyOrderLoadingId)
        .filter((item) => item) as number[],
    }

    try {
      const {
        data: { message },
      } = await putOnDelivery(payload)

      toastSuccess(message)
      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    }
  },
)

export const updateSupplyOrderGrandStatus = createAsyncThunk(
  `${SLICE_NAME}/updateSupplyOrderGrandStatus`,
  async (params: { id: number; data: { status: string } }) => {
    const { id, data } = params

    try {
      await putSupplyOrderGrandStatus(id, data)

      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchGetChecker = createAsyncThunk(
  `${SLICE_NAME}/fetchGetChecker`,
  async (
    params: {
      locationId: number
      callback?: (
        staff: {
          staff_id: number
          staff_full_name: string
        }[],
      ) => void
    },
    { dispatch },
  ) => {
    try {
      const { locationId, callback } = params
      const {
        data: { data },
      } = await getListStaffByRoleAPI({
        role_name: 'AUTH_OUTBOUND_CHECKER',
        location_id: locationId,
      })

      const staff = data.map((item) => ({
        staff_id: item.staff_id,
        staff_full_name: item.staff_full_name,
      }))

      if (callback) {
        callback(staff)
      }

      dispatch(setCheckerOptions(staff))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchGetPacker = createAsyncThunk(
  `${SLICE_NAME}/fetchGetPacker`,
  async (
    params: {
      locationId: number
      callback?: (
        staff: {
          staff_id: number
          staff_full_name: string
        }[],
      ) => void
    },
    { dispatch },
  ) => {
    try {
      const { locationId, callback } = params
      const {
        data: { data },
      } = await getListStaffByRoleAPI({
        role_name: 'AUTH_OUTBOUND_PACKER',
        location_id: locationId,
      })

      const staff = data.map((item) => ({
        staff_id: item.staff_id,
        staff_full_name: item.staff_full_name,
      }))

      if (callback) {
        callback(staff)
      }

      dispatch(setPackerOptions(staff))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchPostAssignCheckerPacker = createAsyncThunk(
  `${SLICE_NAME}/fetchPostAssignCheckerPacker`,
  async (params: {
    soId: number
    payload: {
      checkerId: number
      packerIds: number[]
    }
    callback?: () => void
  }) => {
    try {
      const { soId, payload, callback } = params

      const { data } = await postAssignCheckerPackerAPI(soId, {
        checker_id: payload.checkerId,
        packer_ids: payload.packerIds,
      })

      if (callback) {
        callback()
      }

      const message = (data as { message?: string })?.message || 'Sukses assign checker packer'
      toastSuccess(message)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
