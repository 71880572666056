import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const ProductAssetListRBACPage = {
  pageID: 'caa59195ccc2b85f9288fff886700124',
  elementID: {
    CreateProduct: 'a6a2f74c0ba63b89c6555ba45373577d',
    EditProduct: '49c754d20a5a589e84688c3e89a16cfd',
    ReviewAsset: '0a93b7826f267437a757ecac29a33591',
    ViewOnly: '12d733cd50b48a038889364fe934f6c5',
  },
} as const

export const useProductAssetListRBAC = createUseRBAC(ProductAssetListRBACPage.elementID)

export type UseProductAssetListRBACPageType = ReturnType<typeof useProductAssetListRBAC>
