import AxiosInstance from 'config/apiServiceApiGateway'

import {
  AssetSupplyOrderParamsInterface,
  AssetSupplyOrderListResponseInterface,
  AssetSupplyOrderCreateParamsInterface,
  AssetSupplyOrderDetailResponseInterface,
  AssetSupplyOrderUpdateParamsInterface,
  AssetSupplyOrderUpdateResponseInterface,
  AssetSupplyOrderMoveableAssetResponseInterface,
  AssetSupplyOrderInventoryCodeResponseInterface,
  AssetSupplyOrderUpdateDataParamsInterface,
  AssetSupplyOrderUpdateItemQuantityInterface,
  AssetSupplyOrderUpdateItemQuantityResponseInterface,
  AssetSupplyOrderAssetInventoryDetailInterface,
} from 'features/AssetManagement/@types/typeSupplyOrder'

export const getSupplyOrderListAPI = (query: AssetSupplyOrderParamsInterface) =>
  AxiosInstance.Get<AssetSupplyOrderListResponseInterface, true>({
    url: '/ams/internal/v1/so/list',
    params: query,
  })

export const getSupplyOrderMoveableAssetAPI = (origin_id: string, search_params?: string) =>
  AxiosInstance.Get<AssetSupplyOrderMoveableAssetResponseInterface, true>({
    url: '/ams/internal/v1/assets_inventory/list',
    params: { origin_id, search_params },
  })

export const postSupplyOrderCreateAPI = (payload: AssetSupplyOrderCreateParamsInterface) =>
  AxiosInstance.Post<AssetSupplyOrderDetailResponseInterface, true>({
    url: '/ams/internal/v1/so/create',
    data: payload,
  })

export const postSupplyOrderUpdateAPI = (payload: AssetSupplyOrderUpdateParamsInterface) =>
  AxiosInstance.Post<AssetSupplyOrderUpdateResponseInterface, true>({
    url: '/ams/internal/v1/so/update_progress',
    data: payload,
  })

export const getSupplyOrderDetailAPI = (so_id: string, asset_inventory_status?: string) =>
  AxiosInstance.Get<AssetSupplyOrderDetailResponseInterface, true>({
    url: `/ams/internal/v1/so/detail`,
    params: { so_id, asset_inventory_status },
  })

// ini untuk check barcode scan di asset page.
export const getSupplyOrderInventoryByCodeAPI = (code: string) =>
  AxiosInstance.Get<AssetSupplyOrderInventoryCodeResponseInterface, true>({
    url: `/ams/internal/v1/assets_master/detail`,
    params: { code },
  })

// ini untuk check barcode scan di wms page check quantity.
export const getSupplyOrderAssetInventoryDetailAPI = (
  code: string,
  type?: string,
  origin?: string,
) =>
  AxiosInstance.Get<AssetSupplyOrderAssetInventoryDetailInterface, true>({
    url: '/ams/internal/v1/assets_inventory/detail',
    params: { code, origin, type },
  })

export const postSupplyOrderUpdateDataAPI = (payload: AssetSupplyOrderUpdateDataParamsInterface) =>
  AxiosInstance.Post<AssetSupplyOrderUpdateResponseInterface, true>({
    url: '/ams/internal/v1/so/update',
    data: payload,
  })

export const postSupplyOrderUpdateItemQuantityAPI = (
  payload: AssetSupplyOrderUpdateItemQuantityInterface,
) =>
  AxiosInstance.Post<AssetSupplyOrderUpdateItemQuantityResponseInterface, true>({
    url: '/ams/internal/v1/so/update_item_quantity',
    data: payload,
  })
