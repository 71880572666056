import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AssetPurchaseOrderDetailResponseInterface,
  POCheckQtyDataInterface,
} from 'features/AssetManagement/@types/typePurchaseOrder'

interface PurchaseOrderAssetStateType {
  isLoading: boolean
  purchaseOrderDetail: AssetPurchaseOrderDetailResponseInterface
  checkQuantityItems: POCheckQtyDataInterface[]
}

const initialState: PurchaseOrderAssetStateType = {
  isLoading: false,
  purchaseOrderDetail: {
    detail: {
      id: 0,
      vendor: {
        vendor_id: '',
        vendor_name: '',
      },
      destination: {
        location_id: '',
        location_name: '',
        location_type: '',
      },
      po_number: '',
      es_shipping_date: '',
      pic: '',
      reference_number: '',
      payment_term: '',
      external_id: 0,
      note: '',
      delivery_fee: 0,
      ppn: true,
      grn_number: '',
      checker_id: 0,
      status: '',
      po_date: '',
      created_at: '',
      total_qty: 0,
      created_by: '',
      updated_by: '',
      expiration_date: '',
      received_at: '',
      received_by: '',
      subtotal: 0,
      subtotal_discount: 0,
      subtotal_tax: 0,
      subtotal_delivery_fee: 0,
      total: 0,
      tax_rate: ``,
      expiry_date: ``,
    },
    items: [],
    asset_inventories: [],
    error: {
      status: false,
      message: '',
      code: 0,
    },
  },
  checkQuantityItems: [],
}

const purchaseOrderAssetDetailSlice = createSlice({
  name: 'purchaseOrderAssetDetail',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPurchaseOrderDetail: (
      state,
      action: PayloadAction<PurchaseOrderAssetStateType['purchaseOrderDetail']>,
    ) => {
      state.purchaseOrderDetail = action.payload
    },
    setCheckQuantityItems: (
      state,
      action: PayloadAction<PurchaseOrderAssetStateType['checkQuantityItems']>,
    ) => {
      state.checkQuantityItems = action.payload
    },
  },
})

export const { setIsLoading, setPurchaseOrderDetail, setCheckQuantityItems } =
  purchaseOrderAssetDetailSlice.actions

export default purchaseOrderAssetDetailSlice.reducer
