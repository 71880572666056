import apiServices from 'config/apiServiceApiGateway'

const { Get } = apiServices

type ErrorType = {
  status: boolean
  message: string
  code: number
}

export type GetRejectOrderReasonListResponseType = {
  data: {
    reasons: string[]
  }
  error: ErrorType
}

export const getRejectOrderReasonList = () =>
  Get<GetRejectOrderReasonListResponseType, true>({
    url: '/fraud/internal/v1/reject_order/reasons',
  })

export type GetApproveOrderReasonListResponseType = {
  data: {
    reasons: string[]
  }
  error: ErrorType
}

export const getApproveOrderReasonList = () =>
  Get<GetApproveOrderReasonListResponseType, true>({
    url: '/fraud/internal/v1/accept_order/reasons',
  })
